import React from "react";

const StatusUnverified = () => {
  return (
    <div>
      <a className="text-warning text-xs mb-0" href="javascript:;">
        Belum Diverifikasi Oleh Dokter
      </a>
    </div>
  );
};

export default StatusUnverified;
